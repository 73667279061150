import pbjs from '../pbjs';
import googletag from '../googletag';
// import debug, { debugVal } from './utils/debug';
import config from '../config/APP_TARGET';
import AdSlot from '../AdSlot';

import getLeaderboardAdUnits from '../config/APP_TARGET/leaderboardAdUnits';
import incontentAdUnits from '../config/APP_TARGET/incontentAdUnits';
import staticAdUnits from '../config/APP_TARGET/staticAdUnits';
import { executeBatch } from '../batchManager';
import { copyArray } from '../utils/copy';
import debug from '../utils/debug';
import leaderBoardHandler from './leaderBoardHandler';
import adhesionHandler from './adhesionHandler';

let initialized = false;
const processedPlacements = [];
const activeUnits = {};

const adUnitMap = {
  leaderboard: getLeaderboardAdUnits('')[0],
  incontent: incontentAdUnits[0],
  adhesion: staticAdUnits[0],
};

const adUnitHandlers = {
  leaderboard: leaderBoardHandler,
  adhesion: adhesionHandler,
};

const init = () => {
  if (initialized) return;

  pbjs.que.push(() => {
    pbjs.setConfig(config.PREBID_CONFIG);
  });

  // setup DFP
  googletag.cmd.push(() => {
    // googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });

  initialized = true;
};

// const loadLeaderboard = div => {
//   if (!config.DISABLE_LEADERBOARD) {
//     init();
//     const leaderboardAdUnits = getLeaderboardAdUnits('');
//     const leaderboardAdSlot = new LeaderboardAdSlot({
//       adUnit: leaderboardAdUnits[0],
//       div,
//     });
//     googletag.cmd.push(() => {
//       pbjs.que.push(() => {
//         leaderboardAdSlot.load();
//         executeBatch();
//       });
//     });
//   }
// };

const pollForPlacements = () => {
  const results = document.querySelectorAll('[adunit]');
  const placements = [...results].filter((e) => !processedPlacements.includes(e));
  if (placements.length === 0) return;
  debug('new placements to process:', placements);

  placements.forEach((placement) => {
    const adUnitName = placement.getAttribute('adunit');
    let adUnit = adUnitMap[adUnitName];
    if (!adUnit) {
      // eslint-disable-next-line no-console
      console.error('cannot find adUnit config for adunit ', adUnitName);
      return;
    }
    adUnit = copyArray(adUnit);
    const divId = `div-gpt-ad-${adUnitName}_${Math.floor(Math.random() * 100000)}`;
    adUnit.code = divId;
    // eslint-disable-next-line no-param-reassign
    placement.id = divId;
    debug(adUnitName, adUnit);

    init();
    const adSlot = new AdSlot({ adUnit, div: placement });
    if (adUnitHandlers[adUnitName]) {
      adUnitHandlers[adUnitName](adSlot);
    }
    googletag.cmd.push(() => {
      pbjs.que.push(() => {
        adSlot.load();
        executeBatch();
      });
    });

    processedPlacements.push(placement);
  });
};

const AdService = {
  init,
  pollForPlacements,
  activeUnits,
};

window.AdService = AdService;

export default AdService;
