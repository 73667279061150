import debug from './utils/debug';
import configBucket from './config/APP_TARGET/autoTestBucket';
import googletag from './googletag';

/**
 * Key for saving the autoconfig in session storage.
 */
const AUTO_CONFIG_KEY = 'autoConfig';

/**
 * Gets the total weights from the config bucket.
 * Extracted from the bucket's weight variable.
 */
const getTotalWeight = () => {
  let totalWeight = 0;
  // eslint-disable-next-line no-return-assign
  configBucket.forEach((config) => (totalWeight += config.weight));
  return totalWeight;
};

/**
 * Saves the selected config in the window's session storage.
 * @param {object} selectedConfig
 */
const saveConfig = (selectedConfig) => {
  window.sessionStorage.setItem(AUTO_CONFIG_KEY, JSON.stringify(selectedConfig));
};

/**
 * Gets the random config by using the bucket's weight variable
 * and using a random number to determine which config to select.
 */
const getAutoConfig = () => {
  // Check if config is already saved in session storage.
  // let selectedConfig = JSON.parse(window.sessionStorage.getItem(AUTO_CONFIG_KEY));
  let selectedConfig = '';

  if (selectedConfig) {
    debug(`AutoTest Loading Saved Config ${selectedConfig.name}`);
  } else {
    const randNum = Math.random() * getTotalWeight();
    debug('AutoTest Random Value ', randNum);

    let currentWeight = 0;

    selectedConfig = configBucket.find((config) => {
      currentWeight += config.weight;
      return randNum <= currentWeight;
    });

    debug(`AutoTest Selected Config ${selectedConfig.name}`);
    saveConfig(selectedConfig);
  }

  // Send dfp key value pair for the selected config
  googletag.cmd.push(() => {
    googletag.pubads().setTargeting('testConfig', selectedConfig.name);
  });

  return selectedConfig.config;
};

export default getAutoConfig;
