/* eslint-disable import/prefer-default-export */
import googletag from './googletag';
import pbjs from './pbjs';
import { getApstagSlotsFromAdunits } from './apstag';
import config from './config/APP_TARGET';
import debug from './utils/debug';
import { filterAdvertisersByStep } from './utils/excludeAdvertisers';
import { getCurrentStep } from './stepManager';
import { copyArray } from './utils/copy';

/** Executes a parallel auction with prebid * */
export const executeAuction = ({ adUnits, prebidBidBackHandler, slots }) => {
  const { NO_ADS_PAGES } = config;
  if (NO_ADS_PAGES) {
    if (NO_ADS_PAGES.find((pattern) => window.location.href.includes(pattern))) {
      debug('Blocking ads for pattern match with', window.location.href);
      return;
    }
  }
  const { apstag } = window;
  const requestManager = {
    adserverRequestSent: false,
    aps: config.DISABLE_AMAZON,
    prebid: false,
  };
  let timeout;

  const filteredAdUnits = copyArray(adUnits);
  filteredAdUnits.forEach((adUnit) => {
    // eslint-disable-next-line no-param-reassign
    adUnit.bids = filterAdvertisersByStep(getCurrentStep(), adUnit.bids);
  });
  debug('executing auction', filteredAdUnits, prebidBidBackHandler, slots);

  const slotCodes = slots
    .map((slot) => (slot.getSlotElementId && slot.getSlotElementId()) || '')
    .filter((id) => id);

  debug('slot codes:', slotCodes);

  // sends adserver request
  const sendAdserverRequest = () => {
    if (requestManager.adserverRequestSent === true) {
      return;
    }
    clearTimeout(timeout);
    debug('sending ad server request', slots);
    requestManager.adserverRequestSent = true;
    googletag.cmd.push(() => {
      googletag.pubads().refresh(slots);
    });
  };

  // when both APS and Prebid have returned, initiate ad request
  const biddersBack = () => {
    if (requestManager.aps && requestManager.prebid) {
      sendAdserverRequest();
    }
  };

  // sends bid request to APS and Prebid
  const requestHeaderBids = () => {
    const amazonSlots = getApstagSlotsFromAdunits(filteredAdUnits);

    if (!config.DISABLE_AMAZON) {
      // APS request
      apstag.fetchBids(
        {
          slots: amazonSlots,
          timeout: config.PREBID_TIMEOUT,
        },
        (bids) => {
          debug('Amazon bids:', bids);
          googletag.cmd.push(() => {
            apstag.setDisplayBids();
            requestManager.aps = true; // signals that APS request has completed
            biddersBack(); // checks whether both APS and Prebid have returned
          });
        },
      );
    }
    // put prebid request here
    pbjs.que.push(() => {
      pbjs.bidderSettings = config.PREBID_BIDDER_SETTINGS;
      pbjs.requestBids({
        adUnitCodes: slotCodes,
        bidsBackHandler() {
          googletag.cmd.push(() => {
            pbjs.setTargetingForGPTAsync(slotCodes);
            if (prebidBidBackHandler) prebidBidBackHandler();
            requestManager.prebid = true; // signals that Prebid request has completed
            biddersBack(); // checks whether both APS and Prebid have returned
          });
        },
        timeout: config.PREBID_TIMEOUT,
      });
    });
  };

  // initiate bid request
  requestHeaderBids();

  // set failsafe timeout
  timeout = window.setTimeout(() => {
    debug('FAILSAFE TIMEOUT', filteredAdUnits);
    sendAdserverRequest();
  }, config.DISPLAY_FAILSAFE_TIMEOUT);
};
