/* eslint-disable no-console */

// eslint-disable-next-line import/no-mutable-exports
export let debugVal = window.location.href.indexOf('pbjs_debug=true') !== -1;

window.enableDebug = () => {
  debugVal = true;
};

function decorateLog(args, prefix) {
  const localArgs = [].slice.call(args);
  if (prefix) localArgs.unshift(prefix);
  localArgs.unshift(
    'display: inline-block; color: #fff; background: #f88; padding: 1px 4px; border-radius: 3px;',
  );
  localArgs.unshift('%cMamasUncut');
  return localArgs;
}

function debug(...args) {
  if (debugVal) console.log.apply(null, decorateLog(args, ''));
  // else console.debug.apply(null, decorateLog(arguments, ''));
}

export function logError(...args) {
  if (debugVal) console.error.apply(null, decorateLog(args, ''));
}

export function log(...args) {
  if (debugVal) console.log.apply(null, decorateLog(args, ''));
}

export default debug;
