import adState from './adState';
import pbjs from './pbjs';
import googletag from './googletag';
import debug from './utils/debug';
import config from './config/APP_TARGET';
import { setFloorTestTargeting } from './targeting';
import { executeBatch } from './batchManager';

let refreshTimer = null;
let lastRenderTime = null;

let leaderboardRendered = false;
let adhesionRendered = false;
let renderTimeoutReached = false;
let stepChanged = false;
let renderTimeout = null;

// function declarations
let onRenderTimeout = null;
let onRendered = null;

/**
 * Refresh footer
 */
export function refreshAdhesionBid() {
  if (window.adhesionClosed || config.DISABLE_FOOTER || !window.adhesionSlot) return;

  googletag.cmd.push(() => {
    const slots = [];
    slots.push(window.adhesionSlot.slot);

    pbjs.que.push(() => {
      pbjs.setConfig({
        useBidCache: true,
        cache: {
          url: 'https://prebid.adnxs.com/pbc/v1/cache',
        },
        priceGranularity: 'high',
        bidderSequence: 'random',
      });

      adState.adhesionRefreshInterationCount =
        parseInt(adState.adhesionRefreshInterationCount, 10) + 1;
      window.adhesionSlot.setTargeting({
        refreshIteration: adState.adhesionRefreshInterationCount.toString(),
      }); // Set Slot Tartgeting

      // force close underdog's unit
      if (window.top.closeUdmAd) {
        window.top.closeUdmAd();
      }

      debug(`refreshIteration - ${adState.adhesionRefreshInterationCount}`);

      // Set Key Value Targeting
      debug(`adhesion article_id: ${adState.postID.toString()}`);

      window.adhesionSlot.setTargeting({ article_id: adState.postID.toString() });
      setFloorTestTargeting(); // floortest targeting on refresh

      // Set UTM Targeting
      if (adState.ascUTMCookieValObj) {
        window.adhesionSlot.setTargeting(adState.ascUTMCookieValObj);
      }

      window.adhesionSlot.refresh();
    });
  });
}

export function refreshLeaderboard() {
  if (config.DISABLE_LEADERBOARD) return;
  const leaderboardAdSlots = adState.globalLeaderboardAdSlots;
  leaderboardAdSlots.forEach((adSlot) => adSlot.refresh());
}

/**
 * Refreshes leaderboard and footer
 */
export const refreshStaticAds = () => {
  const timeSinceLastRefresh = Date.now() - lastRenderTime;
  debug(
    'Viewability trigger - seconds elapsed since last static ad refresh: ',
    timeSinceLastRefresh / 1000,
  );

  // if not enough time has elapsed, retry after enough time
  if (timeSinceLastRefresh < config.REFRESH_MINIMUM_TIME) {
    clearTimeout(refreshTimer);
    refreshTimer = setTimeout(refreshStaticAds, config.REFRESH_MINIMUM_TIME - timeSinceLastRefresh);
    return;
  }

  debug('Refreshing static ads');
  debug('Viewability trigger - Refreshing static ads');
  refreshLeaderboard();
  refreshAdhesionBid();
  executeBatch();

  // reset render control variables.
  leaderboardRendered = false;
  adhesionRendered = false;
  renderTimeoutReached = false;
  stepChanged = false;

  // reset render timer
  clearTimeout(renderTimeout);
  renderTimeout = setTimeout(onRenderTimeout, config.VIEWABILITY_RENDER_TIMEOUT);

  // reset refresh timer.
  clearTimeout(refreshTimer);
  refreshTimer = setTimeout(refreshStaticAds, config.REFRESH_RATE);
};

// Checks to see if all rendered events occured.
const hasRenderedAll = () =>
  ((leaderboardRendered || !adState.globalLeaderboardAdSlots.length) &&
    (adhesionRendered || !window.adhesionSlot)) ||
  renderTimeoutReached;

// Callback for when rendering or render timeout has happened.
onRendered = () => {
  if (hasRenderedAll()) {
    clearTimeout(renderTimeout);
    lastRenderTime = Date.now();

    if (stepChanged) {
      refreshStaticAds();
    }
  }
};

// Event for when render timeouts.
onRenderTimeout = () => {
  debug('Viewability trigger - render timeout');
  renderTimeoutReached = true;
  onRendered();
};

// event fired when an aduinit has rendered.
googletag.cmd.push(() => {
  window.googletag.pubads().addEventListener('impressionViewable', (e) => {
    if (hasRenderedAll()) return;

    // Footer
    if (window.adhesionSlot && (config.DISABLE_FOOTER || e.slot === window.adhesionSlot.slot)) {
      debug('Viewability trigger - rendered adhesion slot');
      adhesionRendered = true;
      onRendered();
    }

    // Header
    const leaderboardSlot = adState.globalLeaderboardAdSlots.find((slot) => slot.slot === e.slot);
    if (config.DISABLE_LEADERBOARD || leaderboardSlot) {
      debug('Viewability trigger - rendered leaderboard slot');
      leaderboardRendered = true;
      onRendered();
    }
  });
});

// Event for when step has changed.
export const refreshOnStepChange = () => {
  debug('Viewability trigger - step change');
  stepChanged = true;

  if (hasRenderedAll()) {
    refreshStaticAds();
  }
};

// execute on load
renderTimeout = setTimeout(onRenderTimeout, config.VIEWABILITY_RENDER_TIMEOUT);
refreshTimer = setTimeout(refreshStaticAds, config.REFRESH_RATE);
