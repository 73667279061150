// eslint-disable-next-line import/prefer-default-export
import debug from './debug';

export function hasTrailingSlash(url) {
  return url.charAt(url.length - 1) === '/';
}

export function urlWithoutTrailingSlash(url) {
  if (hasTrailingSlash(url)) {
    return url.substring(0, url.length - 1);
  }

  return url;
}

export const formatAllUrlParams = (params) => {
  let values = '';
  params.forEach((param) => {
    values += `?${param}`;
  });
  return values;
};

export const getBaseUrl = () => urlWithoutTrailingSlash(window.location.href).split('?')[0];

export const getBaseUrlNoStep = (url) => url.split('/?')[0].split('/step')[0];

export const getUrlParams = () =>
  formatAllUrlParams(urlWithoutTrailingSlash(window.location.href).split('/?').slice(1));

export const setBrowserUrl = (url) => {
  window.history.replaceState({}, '', url);
  debug('set url', url);
};

export const addToBaseUrl = (str) => {
  setBrowserUrl(`${getBaseUrl()}/${str}`);
};

// Get Query Parameters
export const getAllUrlParams = (url) => {
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  const obj = {};

  if (queryString) {
    [queryString] = queryString.split('#');
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      const a = arr[i].split('=');
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      if (paramName.match(/\[(\d+)?\]$/)) {
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        if (paramName.match(/\[\d+\]$/)) {
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else if (!obj[paramName]) {
        obj[paramName] = paramValue;
      } else if (obj[paramName] && typeof obj[paramName] === 'string') {
        obj[paramName] = [obj[paramName]];
        obj[paramName].push(paramValue);
      } else {
        obj[paramName].push(paramValue);
      }
    }
  }
  return obj;
};
