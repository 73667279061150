import { addToBatch } from './batchManager';
import config from './config/APP_TARGET';
import googletag from './googletag';
import pbjs from './pbjs';
import debug, { logError } from './utils/debug';

export default class AdSlot {
  constructor({ adUnit, div, targeting }) {
    this.adUnit = adUnit;
    this.div = div;
    this.targeting = targeting || {};
    this.setupSlot();
  }

  destroy() {
    if (!this.slot) return;
    googletag.cmd.push(() => {
      googletag.destroySlots([this.slot]);
      this.slot = null;
    });
  }

  setupSlot() {
    googletag.cmd.push(() => {
      this.slot = googletag
        .defineSlot(
          `/${config.DFP_NETWORK}/${this.adUnit.adunit}`,
          this.adUnit.mediaTypes.banner.sizes,
          this.adUnit.code,
        )
        .addService(googletag.pubads());

      // display ad unit
      googletag.display(this.adUnit.code);

      this.setTargeting(this.targeting);
      pbjs.que.push(() => {
        pbjs.addAdUnits([this.adUnit]);
      });
    });
  }

  setTargeting(targeting) {
    this.targeting = { ...this.targeting, ...targeting };
  }

  load() {
    this.refresh();
  }

  refresh() {
    if (!this.slot) {
      logError(
        "Trying to refresh AdUnit with no slot. Either it wasn't setup properly or destroyed.",
      );
      return;
    }
    // set slot targetting
    Object.keys(this.targeting).forEach((key) => {
      debug(`${key}--${this.targeting[key]}`);
      this.slot.setTargeting(key, this.targeting[key]);
    });

    addToBatch(this);
  }
}
