// Detect Window Width
const detectWidth = () =>
  window.screen.width ||
  window.innerWidth ||
  window.document.documentElement.clientWidth ||
  Math.min(window.innerWidth, window.document.documentElement.clientWidth) ||
  window.innerWidth ||
  window.document.documentElement.clientWidth ||
  window.document.getElementsByTagName('body')[0].clientWidth;
// Detect Device Category
const detectDevice = () => {
  const width = detectWidth();
  if (width >= 992) return 'Desktop';
  if (width >= 768) return 'Tablet';
  return 'Mobile';
};

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};

export const mobileOs = getMobileOperatingSystem();
export const deviceWidth = detectWidth();
export const deviceName = detectDevice();
