let pageStep = 0;

const stepListeners = [];

export const addStepListener = (listener) => stepListeners.push(listener);

export const getCurrentStep = () => pageStep;

export const setCurrentStep = (value) => {
  const listenersToRemove = [];
  pageStep = value;

  // inform each listener of the step change
  stepListeners.forEach((listener) => {
    const res = listener(pageStep);

    // mark listeners that returned `true` for removal
    if (res) listenersToRemove.push(listener);
  });

  // remove listeners marked for removal
  listenersToRemove.forEach((listener) => {
    stepListeners.splice(stepListeners.indexOf(listener), 1);
  });
};
