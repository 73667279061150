// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Make leaderboard sticky*/
.dyn-asc-ad {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.closeAdButton {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  z-index: 99999;
  border-radius: 50%;
  background: #ccc;
  opacity: 0.5;
  color: #000;
  text-decoration: none;
}

.closeAdButton:hover {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  z-index: 99999;
  border-radius: 50%;
  background: #ccc;
  opacity: 0.5;
  color: #fff;
  text-decoration: none;
}

`, "",{"version":3,"sources":["webpack://./src/styles/leaderboard.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,wBAAwB;EACxB,gBAAgB;EAChB,MAAM;EACN,YAAY;AACd;AACA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,qBAAqB;AACvB","sourcesContent":["/* Make leaderboard sticky*/\n.dyn-asc-ad {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  z-index: 100;\n}\n.closeAdButton {\n  display: block;\n  width: 24px;\n  height: 24px;\n  padding: 0;\n  margin: 0;\n  background: none;\n  position: absolute;\n  top: 5px;\n  right: 10px;\n  text-align: center;\n  line-height: 22px;\n  cursor: pointer;\n  z-index: 99999;\n  border-radius: 50%;\n  background: #ccc;\n  opacity: 0.5;\n  color: #000;\n  text-decoration: none;\n}\n\n.closeAdButton:hover {\n  display: block;\n  width: 24px;\n  height: 24px;\n  padding: 0;\n  margin: 0;\n  background: none;\n  position: absolute;\n  top: 5px;\n  right: 10px;\n  text-align: center;\n  line-height: 22px;\n  cursor: pointer;\n  z-index: 99999;\n  border-radius: 50%;\n  background: #ccc;\n  opacity: 0.5;\n  color: #fff;\n  text-decoration: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
