if (window.mu_queue && typeof window.mu_queue !== 'function') {
  window.mu_queue.forEach((func) => {
    func();
  });
}

window.mu_queue = {
  push: (func) => {
    func();
  },
};
