import googletag from './googletag';
// eslint-disable-next-line import/prefer-default-export
export function setFloorTestTargeting() {
  const randomNum = Math.random();
  if (randomNum > 0.8) {
    // Call on click
    const subRandomNum = Math.random();
    if (subRandomNum > 0.75) {
      googletag.pubads().setTargeting('floortest', '4');
    } else if (subRandomNum > 0.5) {
      googletag.pubads().setTargeting('floortest', '3');
    } else if (subRandomNum > 0.25) {
      googletag.pubads().setTargeting('floortest', '2');
    } else {
      googletag.pubads().setTargeting('floortest', '1');
    }
  } else {
    googletag.pubads().setTargeting('floortest', 'false');
  }
}
