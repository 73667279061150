import config from '.';
import debug from '../../utils/debug';
import { deviceName } from '../../utils/device';
import { filterAdvertisers } from '../../utils/excludeAdvertisers';
import { incontentSize } from './adSizes';

/* Dynamic AdUnits */
const inContentAdUnits = [
  {
    adunit: 'Answers_MU_IC_A',
    code: 'div-gpt-ad-Answers_MU_IC_A',
    mediaTypes: {
      banner: { sizes: incontentSize },
    },
    bids: [
      {
        bidder: 'appnexus',
        params: { placementId: 20283598 },
      },
      {
        bidder: 'emx_digital',
        params: { tagid: '139116' },
      },
      {
        bidder: 'rhythmone',
        params: { placementId: '231953' },
      },
      {
        bidder: 'pubmatic',
        params: { publisherId: '158314' },
      },
      {
        bidder: 'yieldmo',
        params: { placementId: '2585462486505299995' },
      },
      {
        bidder: 'openx',
        params: {
          unit: '542354968',
          delDomain: 'ascendeum-d.openx.net',
        },
      },
      {
        bidder: '33across',
        params: {
          siteId: 'aDc8qSb_ir66uYaKlId8sQ',
          productId: 'siab',
        },
      },
      {
        bidder: 'sharethrough',
        params: {
          pkey: 'b5SCuwE4n9Z8zFKxZD5hUEaA',
        },
      },
      {
        bidder: 'onetag',
        params: {
          pubId: '707e87471c4b096',
          ext: { unit: 'Answers_MU_IC_A' },
        },
      },
      {
        bidder: 'nativo',
        params: {
          placementId: '1164963',
        },
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '500878',
          pageId: '1561591',
          formatId: '112690',
        },
      },
    ],
  },
];

// Device related bids
if (deviceName === 'Desktop' || deviceName === 'Tablet') {
  // incontent
  inContentAdUnits[0].bids.push(
    {
      bidder: 'ix',
      params: { siteId: '582660', size: incontentSize[0] },
    },
    {
      bidder: 'sovrn',
      params: { tagid: '780630' },
    },
    {
      bidder: 'ix',
      params: { siteId: '582660', size: incontentSize[1] },
    },
    {
      bidder: 'gumgum',
      params: { inSlot: '87049', zone: 'nhdx0x8b' },
    },
    {
      bidder: 'triplelift',
      params: { inventoryCode: 'Answers_MU_IC_A_desktop' },
    },
  );
}
if (deviceName === 'Mobile') {
  inContentAdUnits[0].bids.push(
    {
      bidder: 'ix',
      params: { siteId: '582660', size: incontentSize[0] },
    },
    {
      bidder: 'kargo',
      params: { placementId: '_lO6ArzuweK' },
    },
    {
      bidder: 'triplelift',
      params: { inventoryCode: 'Answers_MU_IC_A_mobile' },
    },
    {
      bidder: 'gumgum',
      params: { inSlot: '87048', zone: 'nhdx0x8b' },
    },
    {
      bidder: 'sovrn',
      params: { tagid: '780616' },
    },
  );
}

inContentAdUnits.forEach((adUnit) => {
  adUnit.bids.push({
    bidder: 'nextMillennium',
    params: {
      placement_id: { Desktop: '21517', Tablet: '21518', Mobile: '21518' }[deviceName],
    },
  });
});
// filter out excluded advertisers.
inContentAdUnits.forEach((adUnit) => {
  // eslint-disable-next-line no-param-reassign
  adUnit.bids = filterAdvertisers(config.EXCLUDED_IC_ADVERTISERS, adUnit.bids);
});

debug('Filter Ads: filtered inContentAdUnits', inContentAdUnits);

export default inContentAdUnits;
