import adState from '../adState';
import createCloseButton from '../leaderboardButton';
// import config from '../config/APP_TARGET';
// import googletag from '../googletag';

import '../styles/leaderboard.css';
// Leaderboard handler for AdService (currently used only in answers)

const makeSticky = (adSlot) => {
  const parent = adSlot.div.parentElement.parentElement.parentElement.parentElement.parentElement;
  parent.style.display = 'block';
  parent.style.position = 'sticky';
  // parent.style.top = '60px';
  parent.style.zIndex = '1000';
};

const setupCloseButton = (adSlot) => {
  // eslint-disable-next-line no-param-reassign

  const leaderBoardCloseButton = createCloseButton('closeLeaderboard');
  adSlot.div.parentElement.appendChild(leaderBoardCloseButton);
};

export default (adSlot) => {
  if (adState.globalLeaderboardAdSlots.length > 0) {
    adState.globalLeaderboardAdSlots[0].destroy();
  }
  adState.globalLeaderboardAdSlots = [adSlot];
  try {
    makeSticky(adSlot);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  try {
    setupCloseButton(adSlot);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // make leaderboard stick at the top when scrolling
  let lastScrollPos = window.scrollY;
  window.addEventListener('scroll', () => {
    const { top } = adSlot.div.parentElement.getBoundingClientRect();
    const direction = window.scrollY - lastScrollPos;
    lastScrollPos = window.scrollY;
    if (top <= 60 && direction > 0) {
      adSlot.div.parentElement.classList.add('fixedTop');
    }
    if (window.scrollY < 60 && direction < 0) adSlot.div.parentElement.classList.remove('fixedTop');
  });

  // event fired when an aduinit has rendered.
  // googletag.cmd.push(() => {
  //   window.googletag.pubads().addEventListener('slotRenderEnded', e => {
  //     // Header
  //     const leaderboardSlot = adState.globalLeaderboardAdSlots.find(slot => slot.slot === e.slot);
  //     if (leaderboardSlot) {
  //       adSlot.div.parentElement.style.display = 'block';
  //     }
  //   });
  // });
};
