/* eslint-disable import/no-mutable-exports */
import { deviceName } from '../../utils/device';

/* Ad Unit Sizes */

export const sizes = {
  leaderboard: {
    desktop: [[728, 90]],
    mobile: [[320, 50]],
    tablet: [[728, 90]],
  },
};

export const getSizes = (adUnit) => sizes[adUnit][deviceName.toLowerCase()];

// old size code below still used for incontent and adhesion. Need to refactor this

export let adhesionSize = [
  [970, 90],
  [728, 90],
];

export let incontentSize = [
  [728, 90],
  [300, 250],
];

if (deviceName === 'Tablet') {
  adhesionSize = [[728, 90]];
  incontentSize = [
    [728, 90],
    [300, 250],
  ];
}
if (deviceName === 'Mobile') {
  adhesionSize = [[320, 50]];
  incontentSize = [[300, 250]];
}
