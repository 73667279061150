import config from './config/APP_TARGET';

/* eslint-disable */
/**
 * Amazon publisher tags
 */

!(function(a9, a, p, s, t, A, g) {
  if (a[a9]) return;
  function q(c, r) {
    a[a9]._Q.push([c, r]);
  }
  a[a9] = {
    init() {
      q('i', arguments);
    },
    fetchBids() {
      q('f', arguments);
    },
    setDisplayBids() {},
    targetingKeys() {
      return [];
    },
    _Q: [],
  };
  A = p.createElement(s);
  A.async = !0;
  A.src = t;
  g = p.getElementsByTagName(s)[0];
  g.parentNode.insertBefore(A, g);
})('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js');

window.apstag.init({
  pubID: config.AMAZON_PUB_ID, //enter your pub ID here as shown above, it must within quotes
  adServer: 'googletag'
});

/**
 * 
 * slots: [{
                    slotID: 'your-gpt-div-id',
                    slotName: '12345/yourAdUnit',
                    sizes: [[300, 250], [300, 600]]
                }]
 */
export const getApstagSlotsFromAdunits= adUnits =>{
  const slots=[]
  adUnits.forEach(adUnit => {
    const slot = {
      slotID: adUnit.code,
      slotName: `/${config.DFP_NETWORK}/${adUnit.adunit}`,
      sizes: adUnit.mediaTypes.banner.sizes
    }
    slots.push(slot)
  })
  return slots
}

const apstag = window.apstag
export default apstag
