// this is the main config each bucket will extend
export default {
  DFP_NETWORK: 21890672457,
  AMAZON_PUB_ID: '6e3963c5-043a-414c-9a28-7e7d1bcf02a1',
  PREBID_TIMEOUT: 2000,
  REFRESH_RATE: 30000,

  VIEWABILITY_RENDER_TIMEOUT: 3000,

  /**
   * Failsafe for display ad auctions. If prebid and amazon auctions don't complete in this time, show google ads
   */
  DISPLAY_FAILSAFE_TIMEOUT: 2000,

  /**
   * Insert an IC unit after this many <p> or <h1-h4> tags
   * Or if step insertion is enabled, after that many steps
   */
  INCONTENT_AD_SKIP: 2,

  /**
   * How many IC adUnits we have defined before we start repeating k adunits
   */
  INCONTENT_ADUINIT_COUNT: 11,

  INCONTENT_AD_TIMEOUT: 1000,
  INCONTENT_AD_CHECK_TIME: 300000,

  // Prebid setting for now turn off
  PREBID_BIDDER_SETTINGS: {
    // trion: {
    //   bidCpmAdjustment(bidCpm) {
    //     // payment concerns, so lower their bids
    //     return bidCpm * 0.8;
    //   },
    // },
    // // rubicon: {
    // //   bidCpmAdjustment(bidCpm) {
    // //     // 78% rev share
    // //     return bidCpm * 0.78;
    // //   },
    // // },
    // // grid: {
    // //   bidCpmAdjustment(bidCpm) {
    // //     // 90% NET, another 90% due to discp
    // //     return bidCpm * 0.8;
    // //   },
    // // },
    yieldmo: {
      bidCpmAdjustment(bidCpm) {
        // discrep
        return bidCpm * 0.9;
      },
    },
    triplelift: {
      bidCpmAdjustment(bidCpm) {
        return bidCpm * 0.9;
      },
    },
    emx_digital: {
      bidCpmAdjustment(bidCpm) {
        return bidCpm * 0.9;
      },
    },
    // gumgum: {
    //   bidCpmAdjustment(bidCpm) {
    //     // discrep
    //     return bidCpm * 0.75;
    //   },
    // },
    // amx: {
    //   bidCpmAdjustment(bidCpm) {
    //     // discrep
    //     return bidCpm * 0.85;
    //   },
    // },
    // teads: {
    //   bidCpmAdjustment(bidCpm) {
    //     // discrep
    //     return bidCpm * 0.85;
    //   },
    // },
    // sharethrough: {
    //   bidCpmAdjustment(bidCpm) {
    //     // discrepency adjustment
    //     return bidCpm * 0.92;
    //   },
    // },
    nativo: {
      bidCpmAdjustment(bidCpm) {
        return bidCpm * 0.95;
      },
    },
  },

  // default set to 1500 as before
  INCONTENT_UNIT_CONFIG: [
    {
      icUnits: [1, 500],
      offset: 1500,
    },
  ],

  /**
   * Enables V2 of the incontent ad system, with leap-frogging.
   */

  ENABLE_INCONTENT_V2: false,

  /**
   * The number of incontent units to load. Only used when V2 is enabled
   */
  INCONTENT_NUMBER_UNITS: 3,

  // number of paragraphs to skip before inserting first IC unit. V2 only.
  INCONTENT_V2_SKIP_FIRST_PARAGRAPHS: 0,

  /**
   * Elements which ads are to be inserted near.
   */
  INCONTENT_AD_INSERT_SELECTION: ['p', 'h2', 'h3', 'h4', 'figure'],

  /**
   * Loads incontent after steps when true, otherwise will load ads regularly based on INCONTENT_AD_INSERT_SELECTION
   */
  INCONTENT_AD_LOAD_AFTER_STEP: false,

  /**
   * Tag used to insert in content ads.
   */
  // INCONTENT_AD_INSERT_TAG: 'div.ic-insert',
  INCONTENT_AD_INSERT_TAG: null,

  /**
   * The number of 'pages' (or URLs) that change before we fire an ad reload
   */
  REFRESH_AFTER_PAGE_COUNT: 1,

  /**
   * Minumum time that must elapse before we reload ads on scroll
   */
  REFRESH_MINIMUM_TIME: 0,

  /**
   * Selects which type of elements to add steps on.
   */
  STEP_INSERT_SELECTION: '.entry-content > iframe, .entry-content > figure, .entry-content > img',

  /**
   * When true, it disables the leaderboard ad.
   */
  DISABLE_LEADERBOARD: false,

  /**
   * When true, it disables the footer/adhesion ads.
   */
  DISABLE_FOOTER: false,

  /**
   * When true, it disables the interstitial ads.
   */
  DISABLE_INTERSTITIAL: false,

  /**
   * When true, it disables the in content ads.
   */
  DISABLE_IC_ADS: false,

  DISABLE_AMAZON: true,

  /**
   * Combinations of step insertion. Combinations of left and right will add steps at those conditions.
   */
  // STEP_INSERT_SELECTION_LEFT: ['figure', 'iframe', 'img', 'script'],

  // STEP_INSERT_SELECTION_RIGHT: ['p', 'h2', 'h3', 'h4'],

  // overall time we want to wait for auction before just showing video without ads
  VIDEO_ADS_TIMEOUT: 6000,

  /**
   * Array of strings of advertisers to exclude from bidding.
   * Example: to exclude gumgum and ix advertisers from the header:
   * EXCLUDED_HEADER_ADVERTISERS: ['gumgum', 'ix'],
   */
  EXCLUDED_HEADER_ADVERTISERS: [
    // 'sovrn',
    // 'amx',
    // 'pubmatic',
    'gumgum',
    'kargo',
    // 'ix',
    // 'appnexus',
    // 'yieldmo',
    // '33across',
    // 'openx',
    // 'trion',
    // // 'rubicon',
    // // 'underdogmedia',
    // 'triplelift',
    // 'teads',
    // // 'grid',
    // 'sharethrough',
    'zeta_global_ssp',
  ],
  EXCLUDED_FOOTER_ADVERTISERS: [
    // 'sovrn',
    // 'pubmatic',
    // 'amx',
    'gumgum',
    'kargo',
    // 'ix',
    // 'appnexus',
    // 'yieldmo',
    // '33across',
    // 'openx',
    // 'trion',
    // // 'rubicon',
    // // 'underdogmedia',
    // 'triplelift',
    // 'teads',
    // // 'grid',
    // 'sharethrough',
    'zeta_global_ssp',
  ],
  EXCLUDED_IC_ADVERTISERS: [
    // 'sovrn',
    // 'amx',
    // 'pubmatic',
    'gumgum',
    'kargo',
    // 'ix',
    // 'appnexus',
    // 'yieldmo',
    // '33across',
    // 'openx',
    // 'trion',
    // // 'rubicon',
    // // 'underdogmedia',
    // 'triplelift',
    // 'teads',
    // // 'grid',
    // 'sharethrough',
    'zeta_global_ssp',
  ],
  EXCLUDED_VIDEO_ADVERTISERS: [],

  // The config object that gets passed to prebid
  PREBID_CONFIG: {
    useBidCache: true,
    cache: { url: 'https://prebid.adnxs.com/pbc/v1/cache' },
    priceGranularity: 'high',
    bidderSequence: 'random',
    userSync: {
      filterSettings: {
        iframe: {
          bidders: '*', // '*' represents all bidders
          filter: 'include',
        },
      },
    },
    // consentManagement: {
    //   gdpr: {
    //     cmpApi: 'iab',
    //     timeout: 8000,
    //     allowAuctionWithoutConsent: true,
    //     defaultGdprScope: false,
    //   },
    // },
    s2sConfig: {
      accountId: '2c25e787-a6a5-48a6-8468-60fddf4dd176',
      bidders: [
        // '33across', // problems
        // 'ix', // problems
        // 'pubmatic',
        // 'sovrn',
        // 'appnexus',
        // 'openx',
        // 'gumgum',
        // 'yieldmo',
        // 'triplelift',
        // 'sharethrough',
      ],
      defaultVendor: 'appnexus',
      enabled: true,
      endpoint: 'https://prebid.adnxs.com/pbs/v1/openrtb2/auction',
      syncEndpoint: 'https://prebid.adnxs.com/pbs/v1/cookie_sync',
    },
  },

  /**
   * Don't show ads on URLs that match any of these patterns
   */
  NO_ADS_PAGES: [],
};
