import { getAllUrlParams } from '../../utils/url';
import debug from '../../utils/debug';
// base config all other test configs extend
import baseConfig from './base';

// test configs that extend base
import configB from './tests/testB';
import configC from './tests/testC';
import configD from './tests/testD';
import configE from './tests/testE';
import configF from './tests/testF';
import configNoAd from './tests/testNoAd';
import configArb from './tests/arb';
import getAutoConfig from '../../autoTest';

// Map param values to configs.
const configMap = {
  1: configB,
  2: configC,
  3: configD,
  4: configE,
  5: configF,
  noad: configNoAd,
  arb: configArb,
};

const GetType = () => {
  const params = getAllUrlParams();
  debug('utm_exp detected value: ', params.utm_exp);
  debug('utm_medium detected value: ', params.utm_medium);
  if (params.utm_medium && params.utm_medium.indexOf('paid') !== -1 && !params.utm_exp) {
    return 'arb';
  }
  return params.utm_exp;
};

// Select which config to use, or if none added load auto config.
const testConfig = configMap[GetType()];
const cfg = testConfig != null ? testConfig : getAutoConfig();
// merger the selected config over base config
const config = { ...baseConfig, ...cfg };
debug('utm_exp config picked up: ', config);

export default config;
