import config from '.';
import debug from '../../utils/debug';
import { deviceName } from '../../utils/device';
import { filterAdvertisers } from '../../utils/excludeAdvertisers';
import { getSizes } from './adSizes';

const getLeaderboardAdUnitsForPost = (postId) => {
  // Leaderboard adunit
  const leaderboardAdUnits = [
    {
      adunit: 'Answers_MU_Leaderboard',
      code: `div-gpt-ad-MU_Leaderboard_${postId}`,
      mediaTypes: {
        banner: { sizes: getSizes('leaderboard') },
      },
      bids: [
        {
          bidder: 'pubmatic',
          params: { publisherId: '158314' },
        },
        {
          bidder: 'triplelift',
          params: { inventoryCode: 'Answers_MU_Leaderboard' },
        },
        {
          bidder: 'rhythmone',
          params: { placementId: '231953' },
        },
        {
          bidder: 'emx_digital',
          params: { tagid: '139127' },
        },
        {
          bidder: 'sharethrough',
          params: {
            pkey: 'HAHr19Uwssz3NPHNu7MyTEhe',
          },
        },
        {
          bidder: 'appnexus',
          params: { placementId: 20283609 },
        },

        {
          bidder: 'yieldmo',
          params: { placementId: '2585462486320750617' },
        },
        {
          bidder: '33across',
          params: {
            siteId: 'cV14Tsb_er67jnaKkGJozW',
            productId: 'siab',
          },
        },
        {
          bidder: 'openx',
          params: {
            unit: '542354966',
            delDomain: 'ascendeum-d.openx.net',
          },
        },
        {
          bidder: 'onetag',
          params: {
            pubId: '707e87471c4b096',
            ext: { unit: 'Answers_MU_Leaderboard' },
          },
        },
        {
          bidder: 'smartadserver',
          params: {
            siteId: '500878',
            pageId: '1561591',
            formatId: '112691',
          },
        },
      ],
    },
  ];

  // ad a bid for each IX size
  getSizes('leaderboard').forEach((size) => {
    leaderboardAdUnits[0].bids.push({
      bidder: 'ix',
      params: {
        siteId: '582671',
        size,
      },
    });
  });

  // Add gumgum bids
  if (window.deviceName === 'Desktop') {
    leaderboardAdUnits[0].bids.push(
      {
        bidder: 'gumgum',
        params: { inSlot: '87071', zone: 'nhdx0x8b' },
      },
      {
        bidder: 'sovrn',
        params: { tagid: '780628' },
      },
      {
        bidder: 'triplelift',
        params: { inventoryCode: 'Answers_MU_Adhesion_desktop' },
      },
      {
        bidder: 'nativo',
        params: {
          placementId: '1164971',
        },
      },
    );
  } else {
    leaderboardAdUnits[0].bids.push(
      {
        bidder: 'gumgum',
        params: { inSlot: '87070', zone: 'nhdx0x8b' },
      },
      {
        bidder: 'kargo',
        params: { placementId: '_v6V8FwtoRx' },
      },
      {
        bidder: 'triplelift',
        params: { inventoryCode: 'Answers_MU_Adhesion_mobile' },
      },
      {
        bidder: 'sovrn',
        params: { tagid: '780614' },
      },
    );
  }

  leaderboardAdUnits.forEach((adUnit) => {
    adUnit.bids.push({
      bidder: 'nextMillennium',
      params: {
        placement_id: { Desktop: '21513', Tablet: '21514', Mobile: '21514' }[deviceName],
      },
    });
  });

  // filter out excluded advertisers.
  leaderboardAdUnits.forEach((adUnit) => {
    // eslint-disable-next-line no-param-reassign
    adUnit.bids = filterAdvertisers(config.EXCLUDED_HEADER_ADVERTISERS, adUnit.bids);
  });

  debug('Filter Ads: filtered leaderboardAdUnits', leaderboardAdUnits);

  return leaderboardAdUnits;
};

export default getLeaderboardAdUnitsForPost;
