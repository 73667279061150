import { executeAuction } from './auction';

let adSlotBatch = [];

const clearBatch = () => {
  adSlotBatch = [];
};

export const executeBatch = () => {
  const batchAdUnits = [];
  const batchSlots = [];

  adSlotBatch.forEach((adSlot) => {
    batchAdUnits.push(adSlot.adUnit);
    batchSlots.push(adSlot.slot);
  });

  executeAuction({ adUnits: batchAdUnits, slots: batchSlots });
  clearBatch();
};

export const addToBatch = (adSlot) => {
  adSlotBatch.push(adSlot);
};
