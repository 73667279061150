/* eslint-disable import/prefer-default-export */
// Copy an Array or Object in Javascript
export function copyArray(o) {
  let v;
  const output = Array.isArray(o) ? [] : {};
  Object.keys(o).forEach((key) => {
    v = o[key];
    output[key] = typeof v === 'object' ? copyArray(v) : v;
  });
  return output;
}
