export const filterAdvertisers = (excludedAdvertisers, bids) =>
  bids.filter((bid) => !excludedAdvertisers.includes(bid.bidder));

export const filterAdvertisersByStep = (step, bids) =>
  bids.filter((bid) => {
    if (bid.minStep == null && bid.maxStep == null) return true;

    let keepBid = true;
    if (bid.minStep != null) {
      keepBid = step >= bid.minStep;
    }

    if (bid.maxStep != null) {
      keepBid = keepBid && step <= bid.maxStep;
    }

    return keepBid;
  });
