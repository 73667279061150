import createCloseButton from '../leaderboardButton';

const setupCloseButton = (adSlot) => {
  // eslint-disable-next-line no-param-reassign

  const closeButton = createCloseButton('closeAdhesion');
  adSlot.div.parentElement.appendChild(closeButton);
};

export default (adSlot) => {
  setupCloseButton(adSlot);
  window.adhesionSlot = adSlot;
};
