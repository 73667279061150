import config from './config/APP_TARGET';
import debug from './utils/debug';

function closeParent() {
  this.parentNode.style.display = 'none';
}

export default function createCloseButton(idName) {
  const { NO_ADS_PAGES } = config;
  if (NO_ADS_PAGES) {
    if (NO_ADS_PAGES.find((pattern) => window.location.href.includes(pattern))) {
      debug('Blocking ads for pattern match with', window.location.href);
      return null;
    }
  }
  const closeButton = document.createElement('button');
  closeButton.setAttribute('id', idName);
  closeButton.className = 'closeAdButton';
  closeButton.onclick = closeParent;
  closeButton.innerHTML = 'x';
  return closeButton;
}
