import config from '.';
import debug from '../../utils/debug';
import { deviceName } from '../../utils/device';
import { filterAdvertisers } from '../../utils/excludeAdvertisers';
import { adhesionSize } from './adSizes';

const staticAdUnits = [
  {
    adunit: 'Answers_MU_Adhesion',
    code: 'div-gpt-ad-Answers_MU_Adhesion',
    mediaTypes: {
      banner: { sizes: adhesionSize },
    },
    bids: [
      {
        bidder: 'pubmatic',
        params: { publisherId: '158314' },
      },
      {
        bidder: 'emx_digital',
        params: { tagid: '145888' },
      },
      {
        bidder: 'rhythmone',
        params: { placementId: '231953' },
      },
      {
        bidder: 'sharethrough',
        params: {
          pkey: '5TdVw2PBaoch8jjfeUT4w1L1',
        },
      },
      {
        bidder: 'appnexus',
        params: { placementId: 20283581 },
      },
      {
        bidder: 'yieldmo',
        params: { placementId: '2585462486421413914' },
      },
      // {
      //   bidder: 'triplelift',
      //   params: { inventoryCode: 'MamasUncut_Adhesion_HDX' },
      // },
      {
        bidder: 'openx',
        params: {
          unit: '542354967',
          delDomain: 'ascendeum-d.openx.net',
        },
      },
      // {
      //   bidder: 'amx',
      // },
      // // {
      // //   bidder: 'underdogmedia',
      // //   params: {
      // //     siteId: '15181', // PB_EDGE_mamasuncut.com
      // //   },
      // //   // minStep: 10,
      // // },
      // // {
      // //   bidder: 'grid',
      // //   params: {
      // //     uid: 1865,
      // //   },
      // // },
      // // {
      // //   bidder: 'teads',
      // //   params: {
      // //     placementId: '124045',
      // //     pageId: '114204',
      // //   },
      // // },
      // // {
      // //   bidder: 'grid',
      // //   params: {
      // //     uid: 1864,
      // //   },
      // // },
      {
        bidder: '33across',
        params: {
          siteId: 'd4F6iSb_er67jnaKkGJozW',
          productId: 'siab',
        },
      },
      {
        bidder: 'onetag',
        params: {
          pubId: '707e87471c4b096',
          ext: { unit: 'Answers_MU_Adhesion' },
        },
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '500878',
          pageId: '1561591',
          formatId: '112689',
        },
      },
    ],
  },
];

// Device related bids
if (deviceName === 'Desktop') {
  staticAdUnits[0].bids.push(
    {
      bidder: 'ix',
      params: { siteId: '582659', size: adhesionSize[0] },
    },
    {
      bidder: 'ix',
      params: { siteId: '582659', size: adhesionSize[1] },
    },
    // {
    //   bidder: 'sovrn',
    //   params: { tagid: '734605', size: adhesionSize[0] },
    // },
    {
      bidder: 'sovrn',
      params: { tagid: '780629', size: adhesionSize[1] },
    },
    {
      bidder: 'triplelift',
      params: { inventoryCode: 'Answers_MU_Adhesion_desktop' },
    },
    // {
    //   bidder: 'trion',
    //   params: { pubId: '30825', sectionId: '4' },
    // },
    // // {
    // //   bidder: 'rubicon',
    // //   params: {
    // //     accountId: '22178',
    // //     siteId: '315962',
    // //     zoneId: '1613954',
    // //   },
    // // },
    // // {
    // //   bidder: 'sharethrough',
    // //   params: {
    // //     pkey: 'sxdXLyr35AXXiwv9JHhbJP2c',
    // //   },
    // // },
    {
      bidder: 'gumgum',
      params: { inSlot: '87047', zone: 'nhdx0x8b' },
    },
    // {
    //   bidder: 'gumgum',
    //   params: { inSlot: '58281', zone: 'nhdx0x8b' },
    // },
    // // {
    // //   bidder: 'pubmatic',
    // //   params: { publisherId: '158314', adSlot: 'MU@Adhesion@728x90' },
    // // },
    // // {
    // //   bidder: 'pubmatic',
    // //   params: { publisherId: '158314', adSlot: 'MU@Adhesion@970x90' },
    // // },
    // {
    //   bidder: 'pubmatic',
    //   params: { publisherId: '158314' },
    // },
    {
      bidder: 'nativo',
      params: {
        placementId: '1164970',
      },
    },
  );
}
if (deviceName === 'Tablet' || deviceName === 'Mobile') {
  staticAdUnits[0].bids.push(
    {
      bidder: 'ix',
      params: { siteId: '582659', size: adhesionSize[0] },
    },
    // {
    //   bidder: 'rubicon',
    //   params: {
    //     accountId: '22178',
    //     siteId: '315962',
    //     zoneId: '1613954',
    //   },
    // },
    // {
    //   bidder: 'pubmatic',
    //   params: { publisherId: '158314', adSlot: 'MU@Adhesion@320x50' },
    // },
    // {
    //   bidder: 'pubmatic',
    //   params: { publisherId: '158314' },
    // },
    // {
    //   bidder: 'sharethrough',
    //   params: {
    //     pkey: 'sxdXLyr35AXXiwv9JHhbJP2c',
    //   },
    // },
    // {
    //   bidder: 'trion',
    //   params: { pubId: '30825', sectionId: '3' },
    // },
    {
      bidder: 'sovrn',
      params: { tagid: '780615' },
    },
    {
      bidder: 'kargo',
      params: { placementId: '_v6V8FwtoRx' },
    },
    {
      bidder: 'triplelift',
      params: { inventoryCode: 'Answers_MU_Adhesion_mobile' },
    },
    {
      bidder: 'gumgum',
      params: { inSlot: '87046', zone: 'nhdx0x8b' },
    },
  );
}

staticAdUnits.forEach((adUnit) => {
  adUnit.bids.push({
    bidder: 'nextMillennium',
    params: {
      placement_id: { Desktop: '21515', Tablet: '21516', Mobile: '21516' }[deviceName],
    },
  });
});

staticAdUnits.forEach((adUnit) => {
  // eslint-disable-next-line no-param-reassign
  adUnit.bids = filterAdvertisers(config.EXCLUDED_FOOTER_ADVERTISERS, adUnit.bids);
});
debug('Filter Ads: filtered staticAdUnits', staticAdUnits);

export default staticAdUnits;
