// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closeAdButton {
  position: relative;
  right:unset;
  top:unset;
}
.closeAdButton:hover{
  position: relative;
  right:unset;
  top:unset;
}

.adhesionParent {
  position: fixed;
  bottom: 1px;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
}
 .leaderboardParent {
  display:flex;
  justify-content: center;
  width: 100%;
  left: 0;
}

.fixedTop {
  position: fixed;
  z-index: 100000;
  top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/styles/answers.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,OAAO;AACT;CACC;EACC,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,OAAO;AACT;;AAEA;EACE,eAAe;EACf,eAAe;EACf,SAAS;AACX","sourcesContent":[".closeAdButton {\n  position: relative;\n  right:unset;\n  top:unset;\n}\n.closeAdButton:hover{\n  position: relative;\n  right:unset;\n  top:unset;\n}\n\n.adhesionParent {\n  position: fixed;\n  bottom: 1px;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  left: 0;\n}\n .leaderboardParent {\n  display:flex;\n  justify-content: center;\n  width: 100%;\n  left: 0;\n}\n\n.fixedTop {\n  position: fixed;\n  z-index: 100000;\n  top: 60px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
