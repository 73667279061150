// test configs that extend base
import configB from './tests/testB';
import configC from './tests/testC';
import configD from './tests/testD';
import configE from './tests/testE';
import configF from './tests/testF';

/**
 * Bucket for different auto configs to load.
 * Weight: the weight for the chance to select this config.
 * Name: the string for the config name for debuging purposes.
 * Config: the config to load.
 */
const configBucket = [
  {
    weight: 100,
    name: 'control',
    config: {},
  },
  {
    weight: 0,
    name: 'configB',
    config: configB,
  },
  {
    weight: 0,
    name: 'configC',
    config: configC,
  },
  {
    weight: 0,
    name: 'configD',
    config: configD,
  },
  {
    weight: 0,
    name: 'configE',
    config: configE,
  },
  {
    weight: 0,
    name: 'configF',
    config: configF,
  },
];

export default configBucket;
